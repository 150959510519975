<template>
    <div class="main-page">
        <div
            v-if="
                currentComponent !== 'InfoMeeting' &&
                currentComponent !== 'InfoMeeting_old'
            "
        >
            <tabbed-page
                :tabList="tabList"
                @changeTabs="handlerChangeTabs"
            >
            </tabbed-page>
        </div>
        <div class="page-box">
            <component
                :is="currentComponent"
                :meetingId="meetingId"
                :routerReturn="routerReturn"
                @switchClick="switchClick"
                @meetingIdChange="meetingIdChange"
                @routerReturnChange="routerReturnChange"
                @changeCom="changeCom"
                @setPageInitTabs="setPageInitTabs"
            ></component>
        </div>
    </div>
</template>

<script>
import TabbedPage from './Sub/TabbedPage/index.vue'
import InitiationMeeting from "./SManagerOaMeetingOnlineWrapper/InitiationMeeting.vue";
import InfoMeeting from "./MeetingInfo.vue";
import InfoMeeting_old from "./MeetingInfo_old.vue";
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
export default {
    name: "SManagerOaMeetingOnlineWrapper",
    components: {
        TabbedPage,
        InitiationMeeting,
        WaitMeeting: () => import(/* webpackChunkName:'WaitMeeting' */"./SManagerOaMeetingOnlineWrapper/WaitMeeting"),
        HasMeeting: () => import(/* webpackChunkName:'HasMeeting' */"./SManagerOaMeetingOnlineWrapper/HasMeeting"),
        MyMeeting: () => import(/* webpackChunkName:'MyMeeting' */"./SManagerOaMeetingOnlineWrapper/MyMeeting"),
        AllMeeting: () => import(/* webpackChunkName:'AllMeeting' */"./SManagerOaMeetingOnlineWrapper/AllMeeting"),
        InfoMeeting,
        InfoMeeting_old,
    },
    data() {
        return {
            tabList: [
                {
                    id: 0,
                    title: "发起/预约会议",
                    com: 'InitiationMeeting',
                    vHasPermi: ['online:sponsor'],
                },
                {
                    id: 1,
                    title: "待我参会",
                    com: 'WaitMeeting',
                    vHasPermi: ['online:willAttend'],
                },
                {
                    id: 2,
                    title: "我已参会",
                    com: 'HasMeeting',
                    vHasPermi: ['online:attended'],
                },
                {
                    id: 3,
                    title: "我发起的",
                    com: 'MyMeeting',
                    vHasPermi: ['online:launch'],
                },
                {
                    id: 4,
                    title: "所有会议",
                    com: 'AllMeeting',
                    vHasPermi: ['online:all'],
                }
            ],
            comps: [
                'CoworkingCampusOaFormMangeScroll',
                'CoworkingCampusOaProcessHandoverScroll'
            ],
            currentComponent: "",
            meetingId: undefined,
            routerReturn: undefined,
        };
    },
    computed: {
        toMeetingId() {
            return this.$route.query.id;
        },
    },
    created() {
        this.init()
    },
    activated(){
        this.init()
    },
    methods: {
        init () {
            this.tabList = tabPageHasAuth(this.tabList);
            if(this.toMeetingId){
                this.currentComponent = this.toMeetingId
                this.tabList.forEach((item, index) => {
                    if (item.com === this.toMeetingId) {
                        this.$set(item, 'sel', true)
                    } else {
                        this.$set(item, 'sel', false)
                    }
                })

            }else{
                this.currentComponent = this.tabList.map(item => item.com)[0];
            }
            console.log(this.currentComponent,'查看参数',this.toMeetingId);
        },
        handlerChangeTabs (data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.currentComponent = item.com;
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
        changeCom(e){
            console.log('eeeeeee',e);
            let data = this.tabList.find(i => i.com === e)
            this.handlerChangeTabs(data)
        },
        switchClick(page) {
            this.currentComponent = page;
        },
        meetingIdChange(id) {
            this.meetingId = id;
        },
        routerReturnChange(path) {
            this.routerReturn = path;
        },
        setPageInitTabs() {
            this.$eventDispatch('setGlobalPageType', 'list');
            this.currentComponent = "InitiationMeeting";
            this.tabList.forEach((item,index) => {
                index == 0 ? (item.sel = true) : (item.sel = false)
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    margin-right: 10px;
}
</style>
